import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { BsCoin } from "react-icons/bs";
import { ImTarget } from "react-icons/im";
import { FaChartLine } from "react-icons/fa";
import aboutHero from "../assets/img/about-hero.svg";
import inspiration from "../assets/img/SchoolStudent.webp";
import infrastructure from "../assets/img/Store.jpg";
import warrenty from "../assets/img/warrenty.jpeg";
import Service from "../assets/img/Service.jpeg";
import AboutCard from "../components/aboutCard";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fullViewInspiration = () => {
    Swal.fire({
      title: "INSPIRATION",
      text: "At the heart of our brand lies a commitment to innovation and sustainability. We design bags that not only reflect timeless style but also prioritize eco-friendly practices. Each piece is a testament to our dedication to quality and ethical craftsmanship. Join us in our journey to make a positive impact, one beautiful bag at a time.",
    });
  };

  const fullViewInfrastructure = () => {
    Swal.fire({
      title: "INFRASTRUCTURE",
      text: "Our state-of-the-art infrastructure combines advanced technology with eco-friendly practices to ensure efficient and sustainable production. Our facilities are equipped with cutting-edge machinery and operate under rigorous quality controls. We prioritize minimal environmental impact and energy efficiency in every process. This commitment to modern, responsible infrastructure supports our mission to deliver exceptional, sustainable bags.",
    });
  };

  const fullViewIngrediants = () => {
    Swal.fire({
      title: "Product warranty",
      text: "We stand behind the quality of our bags with a comprehensive warranty that covers manufacturing defects. If you encounter any issues within the warranty period, we'll repair or replace your bag at no additional cost. Our goal is to ensure your complete satisfaction and peace of mind. This warranty reflects our commitment to exceptional craftsmanship and customer care.",
    });
  };

  const fullViewCultivation = () => {
    Swal.fire({
      title: "Service",
      text: "we value our customers as the heart of our business. We are dedicated to providing personalized service and exceeding expectations with every interaction. Your feedback drives our continuous improvement, and your satisfaction is our top priority. We strive to build lasting relationships based on trust, respect, and exceptional service.",
    });
  };

  return (
    <>
      <section class="bg-success py-5">
        <div class="container">
          <div class="row align-items-center py-5">
            <div class="col-md-8 text-white">
              <h1>About Us</h1>
              <p>
                At our core, we are dedicated to offering high-quality,
                sustainable bags that combine style and functionality. We strive
                to lead in innovation, exceptional customer service, and ethical
                practices while celebrating diversity and inclusivity.
              </p>
            </div>
            <div class="col-md-4">
              <img src={aboutHero} alt="About Hero" />
            </div>
          </div>
        </div>
      </section>
      <section class="container py-3">
        <div class="row text-center pt-5 pb-3">
          <div class="col-lg-12 m-auto">
            <h1 class="h1">Our Story</h1>
            <p>
              Years ago, a group of artisans set out to design bags that blend
              elegance with sustainability. They used eco-friendly materials and
              crafted each piece with care, driven by a vision to make a
              positive impact. Today, their bags tell a story of style,
              craftsmanship, and a commitment to a better world.
            </p>
          </div>
        </div>
      </section>
      <section class="container py-1">
        <div class="row d-flex justify-content-center mb-5">
          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <ImTarget />
              </div>
              <h2 class="h5 mt-4 text-center">Mission</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                Offer a curated selection of stylish, durable, and eco-friendly
                bags that cater to every occasion, while ensuring exceptional
                customer service and convenience.
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <FaChartLine />
              </div>
              <h2 class="h5 mt-4 text-center">Vision</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                To become the go-to destination for high-quality, sustainable
                bags, recognized for innovation, style, and a commitment to
                environmental and social responsibility.
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <BsCoin />
              </div>
              <h2 class="h5 mt-4 text-center">Values</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                Committed to quality, sustainability, innovation, customer
                satisfaction, integrity, and fostering diversity and inclusion.
              </p>
            </div>
          </div>
        </div>
        <Row>
          <Col lg={3}>
            <AboutCard
              image={inspiration}
              cardStyle="aboutCard"
              title="Inspiration"
              contents="At the heart of our brand lies a commitment to innovation and sustainability..."
              readMore={() => fullViewInspiration()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={infrastructure}
              cardStyle="aboutCard"
              title="Infrastructure"
              contents="Our state-of-the-art infrastructure combines advanced technology with..."
              readMore={() => fullViewInfrastructure()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={warrenty}
              cardStyle="aboutCard"
              title="warrenty"
              contents="We stand behind the quality of our bags with a comprehensive warranty that..."
              readMore={() => fullViewIngrediants()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={Service}
              cardStyle="aboutCard"
              title="Service"
              contents="we value our customers as the heart of our business. We are dedicated to providing..."
              readMore={() => fullViewCultivation()}
            />
          </Col>
        </Row>
      </section>
    </>
  );
}
