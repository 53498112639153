import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";
import { BsBookmarkStarFill, BsStarFill } from "react-icons/bs";
import axios from "axios";
import Rating from "./rating";
import { ToastContainer } from "react-toastify";
import { SERVER_PATH } from "../config/env";
import { userInfo } from "../utils/appInfo";
import { successToaster } from "./../utils/index";

export default function Product() {
  let nav = useNavigate();
  const [count, setCount] = useState(1);
  const [OverAll , setOverAll] = useState("")
  const [rating, setRating] = useState();
  const [data, setData] = useState([]);
  const [Post ,setPost] = useState();
  

  

  const param = useParams();
  let { email = "" } = userInfo;

  let info = {
    user_id: email,
    product_id: param.id,
    rating: rating,
  };
  
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    setCount((prevCount) => prevCount - 1);
  };

  const toggleState = (clickState) => {
    setRating(clickState);
  };
  

  const fetchDataTwo = () => {
    axios
      .post(`${SERVER_PATH}get/ratings.php`, {
        user_id: email,
        product_id: param.id,
      })
      .then((res) => {
        setOverAll(res.data);
        console.log("API response for ratings:", res.data);
        
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  
  const fetchData = () => {
    axios
      .post(`${SERVER_PATH}get/product.php`, {
        product_id: param.id,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  


  const postData = async (e) => {
    e.preventDefault();
    let info = {
      product_id: param.id,
      user_id: email,
      quantity: count,
    };

    axios
      .post(`${SERVER_PATH}post/cart.php`, { ...info })
      .then((res) => {
        successToaster({ msg: "Added to cart" });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const buyPostData = async (e) => {
    e.preventDefault();
    let info = {
      product_id: param.id,
      user_id: email,
      quantity: count,
    };

    axios
      .post(`${SERVER_PATH}post/cart.php`, { ...info })
      .then((res) => {
        // console.log("res",res);
        nav("/cart");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

useEffect(() => {
  fetchData();
  fetchDataTwo(); 
  window.scrollTo(0, 0);
}, [param.id, email]);




  


  useEffect(() => {
    info = {
      ...info,
      rating: rating,
    };
    setPost(info); 
  }, [rating, email, param.id]); 


  const Handle=()=>{
    axios
    .post(`${SERVER_PATH}post/ratings.php`, info)
    .then(() => {
      successToaster({ msg: "Product Rated Successfully" });
    });
  }

  return (
    <>
      {data.map((data) => {
        return (
          <section className="bg-light" key={data.product_id}>
            <div className="container pb-5">
              <div className="row">
                <div className="col-lg-5 mt-5">
                  <div className="card mb-3">
                    <img
                      className="card-img img-fluid"
                      src={data.img1}
                      alt=""
                      id="product-detail"
                    />
                  </div>
                  <div className="row">
                    <div className="col-1 align-self-center"></div>
                    <div
                      id="multi-item-example"
                      className="col-10 carousel slide carousel-multi-item"
                      data-bs-ride="carousel">
                      <div
                        className="carousel-inner product-links-wap"
                        role="listbox">
                        <div className="carousel-item active">
                          <div className="row">
                            <div className="col-4">
                              <a href="#">
                                <img
                                  className="card-img img-fluid"
                                  src={data.img2}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="col-4">
                              <a href="#">
                                <img
                                  className="card-img img-fluid"
                                  src={data.img3}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="col-4">
                              <a href="#">
                                <img
                                  className="card-img img-fluid"
                                  src={data.img4}
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1 align-self-center"></div>
                  </div>
                </div>
                <div className="col-lg-7 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <h1
                        className="h5 text-uppercase"
                        style={{ color: "#757575", fontSize: "25px" }}>
                        {data.product_name}
                      </h1>
                      <p>
                        <span
                          className="list-inline-item text-dark fw-700"
                          style={{ fontSize: "1.4em" }}>
                          <BiRupee style={{ marginTop: "-3px" }} />
                          {data.offer_price * count}
                        </span>
                        <span
                          className="list-inline-item text-secondary"
                          style={{
                            fontSize: "1em",
                            textDecoration: "line-through",
                          }}>
                          <BiRupee style={{ marginTop: "-3px" }} />
                          {data.price}
                        </span>
                        <span
                          className="list-inline-item"
                          style={{ fontSize: "1em", color: "green" }}>
                          {data.offer}%
                        </span>
                      </p>
                      <form>
                        <input
                          type="hidden"
                          name="product-title"
                          value="Activewear"
                        />
                        <div className="col-auto">
                          <ul className="list-inline pb-3">
                            <li className="list-inline-item text-right">
                              Quantity
                              <input
                                type="hidden"
                                name="product-quanity"
                                id="product-quanity"
                                value="1"
                              />
                            </li>
                            <li className="list-inline-item">
                              <span
                                className="btn btn-sm btn-success"
                                id="btn-minus"
                                onClick={handleDecrement}>
                                -
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span
                                className="badge bg-light text-dark"
                                id="var-value">
                                {count}
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span
                                className="btn btn-sm btn-success"
                                id="btn-plus"
                                onClick={handleIncrement}>
                                +
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="row pb-3">
                          <div className="col d-grid">
                            <button
                              onClick={buyPostData}
                              type="submit"
                              className="btn btn-success btn-sm py-2 text-uppercase"
                              name="submit"
                              value="buy"
                              style={{
                                borderRadius: "0",
                                letterSpacing: ".2px",
                                fontWeight: "500",
                              }}>
                              Buy Now
                            </button>
                          </div>
                          <div className="col d-grid">
                            <button
                              onClick={postData}
                              type="submit"
                              className="btn btn-warning btn-sm py-2 text-uppercase"
                              name="submit"
                              value="addtocard"
                              style={{
                                borderRadius: "0",
                                letterSpacing: ".2px",
                                fontWeight: "500",
                              }}>
                              Add To Cart
                            </button>
                          </div>
                        </div>
                      </form>
                      <h6
                        className="text-uppercase mt-3"
                        style={{ color: "#757575" }}>
                        Description:
                      </h6>
                      <p
                        style={{
                          fontSize: "15px",
                          textAlign: "justify",
                          lineHeight: "1.7em",
                          fontWeight: "350",
                        }}>
                        {data.description}
                      </p>
                      <h6
                        className="text-uppercase mt-3"
                        style={{ color: "#000", fontSize: "15px" }}>
                        <HiBadgeCheck
                          style={{
                            marginTop: "-4px",
                            color: "green",
                            fontSize: "20px",
                          }}
                          className="me-1"
                        />
                        Ingrediants:
                      </h6>
                      <ul className="text-capitalize pb-2 ingrediants">
                        <li>{data.ingredients}</li>
                      </ul>
                      <h6
                        className="text-uppercase mt-3"
                        style={{ color: "#000", fontSize: "15px" }}>
                        <HiBadgeCheck
                          style={{
                            marginTop: "-4px",
                            color: "green",
                            fontSize: "20px",
                          }}
                          className="me-1"
                        />
                        Methods of Application:
                      </h6>
                      <p
                        style={{
                          fontSize: "15px",
                          textAlign: "justify",
                          lineHeight: "1.7em",
                          fontWeight: "350",
                        }}>
                        {data.instructions}
                      </p>
                    </div>
                  </div>
                  <div className="card mt-2">
                    <div className="card-body">
                      <div className="d-flex align-items-center position-relative ratingDiv">
                        <h1
                          className="h5 text-capitalize"
                          style={{ color: "#000", fontSize: "20px" }}>
                          Ratings
                        </h1>
                        <br/>
                        <div style={{top:"-5px"}} className="position-absolute top-0 end-0">
                        <button
                          onClick={(e) => toggleState(1)}>
                          Rate Product
                        </button>
                        <br/>
                        <br/>
                        <button onClick={Handle}>
                          Submit Rating
                        </button>
                        </div>
                      


                      </div>
                      <h6
                        className="text-uppercase mt-3 mb-2"
                        style={{ color: "#000", fontSize: "15px" , marginTop: "25px",}}>
                        <BsBookmarkStarFill
                          style={{
                            color: "#f39c12",
                            fontSize: "17px",
                          }}
                          className="me-1"
                        />
                        {OverAll.average_rating}   Overall Ratings
                      </h6>
                      {/* <div className="d-flex align-items-center">
                        <span style={{ fontSize: "1.5em" }}>{OverAll?.user_ratings[0]?.ratings}</span>
                        &nbsp;
                        <BsStarFill />
                        Your Rating
                        &nbsp;
                      </div> */}

                      <div className="d-flex align-items-center">
                        <span style={{ fontSize: "1.5em" }}>{rating}</span>
                        &nbsp;
                        <BsStarFill />
                        &nbsp;
                      </div>
                    </div>
                    {rating === 1 ? (
                      <div className="card-body">
                        <h5>Enter Your Ratings</h5>
                        <Rating
                          rating={rating}
                          onRating={(rate) => {
                            setRating(rate);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>

                </div>
              </div>
            </div>
          </section>
        );
      })}
      <ToastContainer />
    </>
  );
}
