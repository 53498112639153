import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiCaretLeft } from "react-icons/bi";
import { TbCurrencyRupee } from "react-icons/tb";
import CartCard from "../components/cartCard";
import { SERVER_PATH, SERVER_URL } from "../config/env";
import { userInfo } from "./../utils/appInfo";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { errorToaster, successToaster } from "./../utils/index";

export default function Cart() {
  const [data, setData] = useState([]);
  const [ ShippingValue ,setShippingValue] = useState();
  const [cartValue, setCartValue] = useState(0);
  const [cartValueTwo, setCartValueTwo] = useState(0);
  // const [cart, setCart] = useState(0);
  console.log(data);
  
  
  const { email = "" } = userInfo;


  const [addressData, setAddressData] = useState([]);
  const [state, setState] = useState(false);

  

  const addressFetchData = async () => {
    try {
      const res = await axios.post(`${SERVER_PATH}get/user.php`, {
        user_id: email,
      });
      setAddressData(res.data);
      setState(true); // Trigger state change
    } catch (err) {
      // console.log(err);
    }
  };

  // Fetch the initial address data
  useEffect(() => {
    addressFetchData();
  }, []); // Runs once when the component mounts

  // Trigger stateapi when 'state' becomes true
  useEffect(() => {
    if (state) {
      const stateapi = async () => {
        var send = addressData[0].state
        try {
          const value = (await axios.post(`${SERVER_PATH}get/getstate.php`,{state:send})).data;
          setShippingValue(value[0])
        } catch (err) {
          // console.log(err);
        }
      };
      stateapi();
    }
  }, [state]); 


  // useEffect(()=>{
  //   const stateapi = async()=>{
  //     if(state){
  //       console.log(state);
        
  //       var value = (await axios.post(`${SERVER_PATH}get/getstate.php`,state)).data
  //       console.log(value);
        
  //     }
  //   }
  //   stateapi()
  // },[state])
  



  const fetchData = async () => {
    const { data: cData = [] } = await axios.post(
      `${SERVER_PATH}get/cart.php`,
      {
        user_id: email,
      }
    );

    setData(cData);
    
  };


  const Update=()=>{
    fetchData();
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data: cData = [] } = await axios.post(
        `${SERVER_PATH}get/cart.php`,
        {
          user_id: email,
        }
      );
      setData(cData);
    };
  
    fetchData();
  }, [ShippingValue]); // Make sure to run this on mount and when email changes
  
  useEffect(() => {
    let value = 0;
    let valueTwo = 0;
  
    data.map((info) => {
      value = value + info.quantity * info.offer_price;
      const weightKey = info.weight;
      // console.log(weightKey);
  
      if (ShippingValue && ShippingValue[weightKey]) {
        valueTwo += parseFloat(ShippingValue[weightKey]) * info.quantity;
      }
    });
  
    setCartValue(value);
    setCartValueTwo(valueTwo);
  }, [data, ShippingValue]); // Add data and ShippingValue as dependencies
  

  const removeProduct = async (id) => {
    let { email = "" } = userInfo;
    await axios
      .post(`${SERVER_PATH}delete/cart.php`, {
        user_id: email,
        product_id: id,
      })
      .then((res) => {
        fetchData();
        errorToaster({ msg: "Item removed from your cart" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAllProduct = async () => {
    let { email = "" } = userInfo;
    await axios
      .post(`${SERVER_PATH}delete/cart.php`, {
        user_id: email,
      })
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkoutProduct = async (id) => {
    await axios
      .post(`${SERVER_PATH}post/order.php`, {
        user_id: email,
      })
      .then((res) => {
        removeAllProduct();
        successToaster({ msg: "Your order placed successfully" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="h-100 h-custom" style={{ backgroundColor: "#eee" }}>
        <div className="container-fluid py-4 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col">
              <div className="card">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="mb-3">
                        <Link to="/" className="text-body text-decoration-none">
                          <BiCaretLeft
                            className="me-0"
                            style={{ fontSize: "1.5em", marginTop: "-5px" }}
                          />
                          <span>Continue shopping</span>
                        </Link>
                      </div>
                      <hr />

                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <p className="mb-1">Shopping cart</p>
                          <p className="mb-0">
                            You have {data.length} items in your cart
                          </p>
                        </div>
                      </div>
                      <CartCard  data={data} GetValue={Update} deleteValue={removeProduct} />
                    </div>
                    <div className="col-lg-5">
                      <div className="card bg-success text-white rounded-3">
                        <div className="card-body">
                          <h4 className="text-uppercase">Price Details</h4>
                          <hr className="my-3" />


                          <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">Product Cost</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />

                              {cartValue}
                            </p>
                          </div>

                          
                          <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">Shipping price</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />

                              {cartValueTwo}
                            </p>
                          </div>


                          <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">total price</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />

                              {cartValue+cartValueTwo}
                            </p>
                          </div>



                          {/* <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">
                              Delivery Charges
                            </p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {cartValue ? 100 : 0}
                            </p>
                          </div> */}
                          <hr className="my-3" />
                          {/* <div className="d-flex justify-content-between mb-4">
                            <p className="mb-2 text-uppercase">
                              Total(Incl. taxes)
                            </p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {cartValue + cartValue ? 100 : 0}
                            </p>
                          </div> */}
                          <button
                            type="button"
                            className="btn btn-light btn-block btn-sm">
                            <div className="d-flex justify-content-between">
                              <span
                                className="text-uppercase"
                                onClick={() => checkoutProduct()}>
                                Checkout
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
