import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Courosel from "../assets/img/Courosel.jpg";
import { SERVER_PATH } from "../config/env";
import axios from "axios";

// const slideStyles = {
//   height: "270px",
//   width: "97%",
//   margin: "0 auto",
//   display: "block",
// };

export default function Sliders() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log(images);
  
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${SERVER_PATH}get/getcarousel.php`);
        setImages(response.data);
        setLoading(true);
      } catch (error) {
        console.error("Error fetching images", error);
        setLoading(true);
      }
    };
    fetchImages();
  }, []);

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {loading ? (
        <Slider {...settings} style={{ width: "100%" }}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image.path || Courosel}
                alt={`Carousel Image ${index + 1}`}
                className="carousel-image"
              />
            </div>
          ))}
        </Slider>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
