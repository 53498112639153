import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppHeader from "./components/appHeader";
import Contact from "./pages/contact";
import Home from "./pages/home";
import About from "./pages/about ";
import AppFooter from "./components/appFooter";
import HandPurse from "./pages/HandPurse";
import Curtains from "./pages/Curtains";
import BedSheets from "./pages/BedSheets";
import TableMats from "./pages/TableMats";
import KitchenKit from "./pages/KitchenKit";
import Signup from "./pages/signup";
import Cart from "./pages/cart";
import MobNavbar from "./components/mobNavbar";
import Profile from "./pages/profile";
import Login from "./accessCredentials/login";
import ErrorPage from "./pages/error";
import UserContextProvider from "./context/userContext";
import { PrivateRouter } from "./components/loginChecker";
import Product from "./components/product";
import MiniHandBag from "./pages/MiniHandBag";
import TravelBags from "./pages/TravelBags";
import Mats from "./pages/Mats";
import MosquitoMat from "./pages/MosquitoMat";

function App() {
  return (
    <>
      <UserContextProvider>
        <BrowserRouter path="/">
          <AppHeader />
          <MobNavbar />
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/cart"
              element={
                <PrivateRouter>
                  <Cart />
                </PrivateRouter>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRouter>
                  <Profile />
                </PrivateRouter>
              }
            />
            <Route path="/HandPurse" element={<HandPurse />} />
            <Route path="/Curtains" element={<Curtains />} />
            <Route path="/BedSheets" element={<BedSheets />} />
            <Route path="/TableMats" element={<TableMats />} />
            <Route path="/KitchenKit" element={<KitchenKit />} />
            <Route path="/MiniHandBag" element={<MiniHandBag />} />
            <Route path="/TravelBags" element={<TravelBags />} />
            <Route path="/Mats" element={<Mats />} />
            <Route path="/MosquitoMat" element={<MosquitoMat />} />
            {/* others */}
            <Route path="/product/:id" element={<Product />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <AppFooter />
        </BrowserRouter>
      </UserContextProvider>
    </>
  );
}

export default App;
