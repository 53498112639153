import React from "react";
import { Link } from "react-router-dom";
// Product images
import HandPurseSingleProduct from "../components/HandPurseSingleProduct";

export default function HandPurse() {
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-6">
                <ul className="list-inline shop-top-menu pb-3 pt-1">
                  <li className="list-inline-item">
                    <Link
                      className="h3 text-dark text-decoration-none mr-3 text-uppercase"
                      to="#">
                      HandPurse
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <HandPurseSingleProduct />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
