import React from "react";
import { Link } from "react-router-dom";
import { MdCall } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { AiOutlineInstagram } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillSuitHeartFill } from "react-icons/bs";

export default function AppFooter() {
  return (
    <>
      <footer className="bg-dark" id="tempaltemo_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 pt-5">
              <h2 className="h2 text-success border-bottom pb-3 border-light logo">
               N999 Store
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <HiLocationMarker className="me-2 mb-1 text-white" />
                  No. 9. SOUTH Narashimapuram,
                  <br />
                  Light House Corner.
                  <br />
                  Karur - 639001.
                </li>
                <li>
                  <MdCall className="me-2 mb-1" />
                  <a className="text-decoration-none" href="tel:7358116799">
                  7358116799
                  </a>
                </li>
                <li>
                  <GrMail className="me-2 mb-1" />
                  <a
                    className="text-decoration-none"
                    href="mailto:suruangel10@gmail.com">
                    suruangel10@gmail.com
                  </a>
                </li>
                <li>
                  <AiOutlineInstagram className="me-2 mb-1" />
                  <a
                    className="text-decoration-none"
                    href="https://www.instagram.com/n999store?igsh=Z3Zob3g0M3g0MmQ="
                    target="blank">
                    n999store
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-4 pt-5">
              <h2 className="h2 text-light border-bottom pb-3 border-light">
                Products
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <Link className="text-decoration-none" to="/HandPurse">
                  HandPurse
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/Curtains">
                  Curtains
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/BedSheets">
                  Bed Sheets
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/TableMats">
                  Table Mats
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/KitchenKit">
                  Kitchen Kit
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-4 pt-5">
              <h2 className="h2 text-light border-bottom pb-3 border-light">
                Further Info
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <Link className="text-decoration-none" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/about">
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-100 bg-black py-3">
          <div className="container">
            <div className="row pt-2">
              <div className="d-flex justify-content-between col-12">
                <p className="text-left text-light">
                  Copyright &copy; 2024 n999store
                </p>
                <a
                  href="https://vebbox.com/"
                  className="text-decoration-none text-light">
                  Developed by vebbox software solutions with passion
                  <BsFillSuitHeartFill
                    className="ms-1"
                    style={{ color: "#c0392b",marginTop:"-5px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
