import React, { useContext } from "react";
import { GoogleLogout } from "react-google-login";
import { clienId, onLogoutSuccess } from "./globalMethods";
import { FcGoogle } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import { UserContext } from "./../context/userContext";
import { useNavigate } from "react-router-dom";

export default function UserLogout() {
  const { userLogout } = useContext(UserContext);
  let nav = useNavigate();
  const logOut = () => {
    userLogout();
    onLogoutSuccess();
    nav("/");
  };
  return (
    <>
      <GoogleLogout
        clientId={clienId}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            className="px-3 py-2 loginGoogleBtn">
            <FcGoogle className="me-2" style={{ marginTop: "-4px" }} />
            Logout
          </button>
        )}
        buttonText="Logout"
        onLogoutSuccess={logOut}
      />
      <ToastContainer />
    </>
  );
}
