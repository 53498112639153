import React, { useEffect, useState } from "react";
import Login from "../../accessCredentials/login";
import { getLocalStorage } from "./../../helperFunction/index";

export const PrivateRouter = (props) => {
  const isLogin = getLocalStorage("uid") ? true : false;
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    setIsLoad(true);
  }, [isLogin]);

  if (isLogin) {
    return props.children;
  }
  return <>{isLoad && <Login />}</>;
};
