import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/img/MainLogo.jpg";
import {
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";

export default function MobNavbar() {
  const [menuBar, setMenuBar] = useState("collapse");

  const toggleMenu = () => {
    setMenuBar(menuBar === "collapse" ? "collapse show" : "collapse");
  };

  return (
    <>
      <Nav
        className="navbar navbar-expand-lg navbar-light shadow d-lg-none"
        style={{
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
          zIndex: 3,
        }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <Link
            className="logo align-self-center"
            to="/"
            style={{ width: "80%" }}
          >
            <img src={Logo} alt="sivam farms logo" height="auto" width="30%" />
          </Link>

          <button
            className="navbar-toggler border-0"
            type="button"
            onClick={toggleMenu}
            aria-controls="templatemo_main_nav"
            aria-expanded={menuBar === "collapse show" ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`align-self-center navbar-collapse ${menuBar}`}
            id="templatemo_main_nav"
          >
            <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={toggleMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/HandPurse" onClick={toggleMenu}>
                  Hand Purse
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Curtains" onClick={toggleMenu}>
                  Curtains
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/BedSheets" onClick={toggleMenu}>
                  Bed Sheets
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/TableMats" onClick={toggleMenu}>
                  Table Mats
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/KitchenKit" onClick={toggleMenu}>
                  Kitchen Kit
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/MiniHandBag"
                  onClick={toggleMenu}
                >
                  Mini Hand Bag
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/TravelBags" onClick={toggleMenu}>
                  Travel Bags
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Mats" onClick={toggleMenu}>
                  Mats
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/MosquitoMat"
                  onClick={toggleMenu}
                >
                  Mosquito Mat
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about" onClick={toggleMenu}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={toggleMenu}
                  to="/cart"
                  className="nav-icon position-relative text-decoration-none"
                >
                  <AiOutlineShoppingCart style={{ fontSize: "1.7em" }} />
                  <label className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-danger text-light">
                    4
                  </label>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={toggleMenu}
                  to="/profile"
                  className="nav-icon position-relative text-decoration-none"
                >
                  <AiOutlineUser style={{ fontSize: "1.7em" }} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Nav>
    </>
  );
}
