import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { TbCurrencyRupee } from "react-icons/tb";
import { userInfo } from "../utils/appInfo";
import { SERVER_PATH } from "../config/env";
import axios from "axios";

export default function CartCard(props) {
  const { data, deleteValue,GetValue} = props;
  const [cartData, setCartData] = useState(data);
  const [updatedItem, setUpdatedItem] = useState(null); 
  let { email = "" } = userInfo; 
  
  useEffect(() => {
    setCartData(data);
  }, [data]);

  const handleIncrement = (product_id) => {
    const updatedData = cartData.map((item) => {
      if (item.product_id === product_id) {
        const newItem = {
          ...item,
          quantity: item.quantity ? Number(item.quantity) + 1 : 1,
        };
  
        const Data = {
          product_id: item.product_id,
          user_id: email,
          quantity: newItem.quantity, 
          message: "ok"
        };
  
        setUpdatedItem(Data); // You can keep this for reference or other usage
  
        return newItem;
      }
      return item;
    });
    setCartData(updatedData);
    
    postData({
      product_id: product_id,
      user_id: email,
      quantity: updatedData.find((item) => item.product_id === product_id).quantity, 
      message: "ok"
    });
  };
  
  const handleDecrement = (product_id) => {
    const updatedData = cartData.map((item) => {
      if (item.product_id === product_id && item.quantity > 1) {
        const newItem = {
          ...item,
          quantity: item.quantity - 1,
        };
  
        const Data = {
          product_id: item.product_id,
          user_id: email,
          quantity: newItem.quantity, 
          message: "ok"
        };
  
        setUpdatedItem(Data); // You can keep this for reference or other usage
        
        return newItem;
      }
      return item;
    });
    setCartData(updatedData);
    
    postData({
      product_id: product_id,
      user_id: email,
      quantity: updatedData.find((item) => item.product_id === product_id).quantity, 
      message: "ok"
    });
  };
  
  useEffect(() => {
    GetValue();
  }, [cartData]);

  const postData = async (updatedCartData) => {
    try {
      await axios.post(`${SERVER_PATH}post/cart.php`, updatedCartData);
      console.log("Cart updated successfully");
    } catch (err) {
      console.error("Failed to update the cart:", err);
    }
  };

  return (
    <>
      {cartData?.map((d) => (
        <Col className="card mb-3" key={d?.product_id}>
          <div className="card-body shadow">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <img
                    src={d?.img1}
                    className="img-fluid rounded-3"
                    alt="Shopping item"
                    style={{ width: "65px" }}
                  />
                </div>
                <div className="ms-3">
                  <h5>{d?.product_name}</h5>
                  <p className="small mb-0">{d?.category}</p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div style={{ width: "90px" }}>
                  <h5 className="fw-normal mb-0">
                    <button
                      className="btn btn-success btn-sm me-2"
                      onClick={() => handleDecrement(d?.product_id)}
                    >
                      -
                    </button>
                    {d?.quantity ? d.quantity : 0}
                    <button
                      className="btn btn-success btn-sm ms-2"
                      onClick={() => handleIncrement(d?.product_id)}
                    >
                      +
                    </button>
                  </h5>
                </div>
                <div style={{ width: "60px" }}>
                  <h5 className="mb-0">
                    <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                    {d?.offer_price}
                  </h5>
                </div>
                <button
                  onClick={() => deleteValue(d?.product_id)}
                  style={{
                    color: "#c0392b",
                    backgroundColor: "transparent",
                    border: "none",
                    fontSize: "1.3em",
                    marginTop: "-10px",
                  }}
                >
                  <FaTrashAlt />
                </button>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
}
