import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { SERVER_PATH } from "../config/env";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
export default function NavData() {
  const [data, setData] = useState([]);
  const [show, setshow] = useState(false);
  const fetchData = () => {
    axios.post(`${SERVER_PATH}get/all_products.php`, {}).then((res) => {
      setData(res.data);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const dropDown = [
    {
      title: "Hand Purse",
      link: "/HandPurse",
    },
    {
      title: "Curtains",
      link: "/Curtains",
    },
    {
      title: "Bed Sheets",
      link: "/BedSheets",
    },
    {
      title: "Table Mats",
      link: "/TableMats",
    },
    {
      title: "Kitchen Kit",
      link: "/KitchenKit",
    },
  ];
  const dropItems = [
    {
      title: "Mini Hand Bag",
      link: "/MiniHandBag",
    },
    {
      title: "Travel Bags",
      link: "/TravelBags",
    },
    {
      title: "Mats",
      link: "/Mats",
    },
    {
      title: "Mosquito Mat",
      link: "/MosquitoMat",
    },
  ];
  return (
    <>
      {dropDown.map((data, index) => {
        return (
          <Link className="nav-link" to={data.link}>
            {data.title}
          </Link>
        );
      })}
      <div  className="Dropdown-container">
        <Link
          className="nav-link "
          onClick={() => {
            setshow(!show);
          }}
        >
          View More {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </Link>
        {show && (
        <div className="Dropdown-child">
          {dropItems.map((data) => (
            <Link
              className="nav-link dropdata"
              to={data.link}
              onClick={() => setshow()}
            >
              {data.title}
            </Link>
          ))}
        </div>
      )}
      </div>

      
    </>
  );
}
