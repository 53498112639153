import React from "react";
import { Container } from "react-bootstrap";
import UserLogin from "./userLogin";
import loginImg from "../assets/img/loginAuth.png";

export default function Login() {
  return (
    <>
      <Container fluid>
        <div className="my-2 profileIntroDiv">
          <img src={loginImg} alt=""  style={{width:"300px",height:"200px"}}/>
          <div>
            <p className="text-secondary">
              Welcome user create your new account!
            </p>
            <UserLogin />
          </div>
        </div>
      </Container>
    </>
  );
}
