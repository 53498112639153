import React, { useEffect } from "react";
import "../assets/css/slick-theme.css";
import Sliders from "../components/slider";
import ProductCard from "../components/productCard";
import HomeCatMon from "../components/homeCatMon";
import productOne from "../assets/img/HomeImg/HandPurse.jpg";
import productTwo from "../assets/img/HomeImg/Curtains.jpg";
import productThree from "../assets/img/HomeImg/Bedsheets.jpg";
import productFour from "../assets/img/HomeImg/TableMat.jpg";
import productFive from "../assets/img/HomeImg/KitchenKit.jpg";
import productSix from "../assets/img/HomeImg/MiniHandBag.jpg";
import productSeven from "../assets/img/HomeImg/TravelBag.jpg";
import productEight from "../assets/img/HomeImg/Mats.jpg";
import productNine from "../assets/img/HomeImg/Mosquito.jpg";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productData = [
    {
      path: "/HandPurse",
      img: productOne,
      title: "Hand Purse",
    },
    {
      path: "/Curtains",
      img: productTwo,
      title: "Curtains"
    },
    {
      path: "/BedSheets",
      img: productThree,
      title: "Bed Sheets",
    },
    {
      path: "/TableMats",
      img: productFour,
      title: "Table Mats",
    },
    {
      path: "/KitchenKit",
      img: productFive,
      title: "Kitchen Kit",
    },
    
  ];
  const productDataTwo = [
    {
      path: "/MiniHandBag",
      img: productSix,
      title: "Mini Hand Bag",
    },
    {
      path: "/TravelBags",
      img: productSeven,
      title: "Travel Bags"
    },
    {
      path: "/Mats",
      img: productEight,
      title: "Mats",
    },
    {
      path: "/MosquitoMat",
      img: productNine,
      title: "Mosquito Mat",
    }
    
  ];


  return (
    <>
      {/* Slide */}
      <Sliders />

      {/* Products category */}
      <section className="bg-light">
        <div className="container py-3">
          <div className="row text-center py-3">
            <div className="col-lg-6 m-auto">
              <h1 className="h2">Our Products</h1>
              <p>We love curating; We love discovering new products</p>
            </div>
          </div>
          <div className="Home-ProductCard row">
            <ProductCard productData={productData}/>
          </div>
          <div className="Home-ProductCard row">
            <ProductCard productData={productDataTwo}/>
          </div>
        </div>
      </section>

      {/* Categories Menu */}
      <section className="container py-0">
        <div className="row text-center pt-2">
          <div className="col-lg-6 m-auto">
            <h1 className="h2 mt-4" style={{ fontWeight: "400" }}>
              Products Of The Month
            </h1>
            <p>We love curating; We love discovering new products</p>
          </div>
        </div>
        <div className="row">
        <HomeCatMon />
        </div>
      </section>
    </>
  );
}
