import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const titleStyles = {
  fontSize: "11px",
};

export default function ProductCard({productData}) {

  return (
    <>
      {productData.map((data, index) => {
        return (
          <Col xs={4} style={{width:"250px"}} className="ProductCard-Main mb-4" key={index + "product"}>
            <div className="card h-100">
              <Link to={data.path}>
                <img
                  src={data.img}
                  className="card-img-top"
                  alt="sivamfarms products"
                />
              </Link>
              <div className="card-body">
                <Link
                  to={data.path}
                  className="h6 text-decoration-none text-uppercase text-dark"
                  style={titleStyles}>
                  {data.title}
                </Link>
              </div>
            </div>
          </Col>
        );
      })}
      
    </>
  );
}
