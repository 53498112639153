import axios from "axios";
import { toast } from "react-toastify";
import { SERVER_PATH } from "../config/env";

// google auth client id
export const clienId =
  "217174581521-239e7jddhsqgp6sj8mg62pkme9jbs2jo.apps.googleusercontent.com";

// google auth success
export const onSuccess = async (data) => {
  const code = await axios
    .post(`${SERVER_PATH}post/user.php`, {
      user_id: data.email,
      user_name: data.name,
      address: "",
      mobile_no: "",
      avatar: "",
      status: "",
      no_purchase: "",
      total_costof_purchase: "",
      resgistered_date: "",
    })
    .then((res) => {
      return res.status;
    });
  toast.success("Login Successful", {
    position: "top-right",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  return code;
};

// google auth failure
export const onFailure = (res) => {
  toast.error("Something Went Wrong", {
    position: "top-right",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

// google auth logout
export const onLogoutSuccess = () => {
  toast.success("Logout Successful", {
    position: "top-right",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

// api address
export const apiAddress = "http://192.168.125.72/ecom/controllers/api";
