import React, { useContext, useEffect } from "react";
import { clienId, onSuccess, onFailure } from "./globalMethods";
import { GoogleLogin } from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import { UserContext } from "./../context/userContext";
import { setlocalStorage } from "../helperFunction";
import { useNavigate } from "react-router-dom";

export default function UserLogin() {
  const { userData, updateUserData } = useContext(UserContext);
  let nav = useNavigate();
  console.log(userData);
  

  useEffect(() => {}, [userData]);

  const loginSuccess = async (data) => {
    const statusCode = await onSuccess(data.profileObj);
    if (statusCode == 200) {
      nav("/");
      console.log(data?.profileObj);
      setlocalStorage("uid", data?.profileObj?.googleId);
      setlocalStorage("userInfo", JSON.stringify(data?.profileObj));
      updateUserData(data.profileObj);
    }
  };

  return (
    <>
      <GoogleLogin
        style={{ width: "500px" }}
        clientId={clienId}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            className="px-3 py-2 loginGoogleBtn">
            <FcGoogle className="me-2" style={{ marginTop: "-4px" }} />
            Continue with Google
          </button>
        )}
        buttonText="Login"
        onSuccess={loginSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
      <ToastContainer />
    </>
  );
}
