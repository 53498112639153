import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { BsStarFill } from "react-icons/bs";

export default function Rating({ count, rating, color, onRating }) {
  const [hoverRating, setHoverRating] = useState(0);


 
  const getColor = (index) => {
    if (hoverRating >= index) {
      return color.filled;
    } else if (!hoverRating && rating >= index) {
      return color.filled;
    }
    return color.unfilled;
  };

  const starRating = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <BsStarFill
          key={idx}
          className="cursor-pointer"
          onClick={() => onRating(idx)}
          style={{ color: getColor(idx) }}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)} 
        />
      ));
  }, [count, rating, hoverRating, color, onRating]); 

  return <>{starRating}</>;
}

Rating.propTypes = {
  count: PropTypes.number,
  rating: PropTypes.number,
  onRating: PropTypes.func, 
  color: PropTypes.shape({
    filled: PropTypes.string,
    unfilled: PropTypes.string,
  }),
};

// Default props
Rating.defaultProps = {
  count: 5,
  rating: 0,
  color: {
    filled: "#ffe11b",
    unfilled: "#e0e0e0",
  },
};
