import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
// Categories images
import HandBagsSet from "../assets/img/HandBagsSet.jpg";
import KitchenStocks from "../assets/img/KitchenStocks.jpg"
import Carpets from "../assets/img/Carpets.jpg";
import axios from "axios";
import { SERVER_PATH } from "../config/env";

export default function HomeCatMon() {
  const [data,setdata]=useState([]);
  const [load,setload]=useState(false)
  // const data = [
  //   {
  //     img: HandBagsSet,
  //     path: "",
  //     title: "Hand Bags",
  //     description:
  //       "Elevate your style with this chic and functional handbag, perfect for any occasion!",
  //   },
  //   {
  //     img: KitchenStocks,
  //     path: "",
  //     title: "Kitchen Stocks",
  //     description:
  //       "Your essentials in comfort and style, spacious Kitchen new Stocks perfect on the go!",
  //   },
  //   {
  //     img: Carpets,
  //     path: "",
  //     title: "Carpets",
  //     description:
  //       "Transform your space with this luxurious, soft carpet, adding comfort and elegance to any room!",
  //   },
  // ];
  useEffect(()=>{
    const api = async()=>{
      var value = (await axios.get(`${SERVER_PATH}get/product_of_month.php`)).data
      setdata(value)
      // console.log(value);
      
      setload(true);
    }
    api()
  },[]) 
  
 
  return (
    <>
   
    {load ? (
      <Row className="g-4">
        {data.map((d) => {
            return d.status == 1 ? (
            <Col xs={12} md={4} className="p-4 HomeCatMon-Col" key={d.id}>
              <Card>
                <Card.Img 
                  style={{ height: '180px', objectFit: 'cover' }} 
                  variant="top" 
                  src={d.img1} 
                  alt={d.title} 
                />
                <Card.Body>
                  <Card.Title>{d.title}</Card.Title>
                  <Card.Text>{d.product_name}</Card.Text>
                  <Button
                    as={Link}
                    to={`product/${d.product_id}`}
                    className="text-decoration-none"
                    variant="success"
                  >
                    View Product
                  </Button>
                </Card.Body>
              </Card>
            </Col>
           ) : null;
        })}
      </Row>
    ) : (
      <h1>Loading...</h1>
    )}
  </>

  );
}
