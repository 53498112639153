import React, { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="container-fluid bg-light py-5">
        <div class="col-md-6 m-auto text-center">
          <h1 class="h1">Contact Us</h1>
          <p>
            Proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum. Lorem ipsum dolor sit amet.
          </p>
        </div>
      </div>
      {/* <div id="mapid" style={{ width: "100%", height: "300px" }}></div> */}
      <div class="container">
        <div class="row py-5">
          <form class="col-md-9 m-auto" method="post">
            <div class="row">
              <div class="form-group col-md-6 mb-3">
                <label for="inputname">Name</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="name"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div class="form-group col-md-6 mb-3">
                <label for="inputemail">Email</label>
                <input
                  type="email"
                  class="form-control mt-1"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
              </div>
            </div>
            <div class="mb-3">
              <label for="inputsubject">Subject</label>
              <input
                type="text"
                class="form-control mt-1"
                id="subject"
                name="subject"
                placeholder="Subject"
              />
            </div>
            <div class="mb-3">
              <label for="inputmessage">Message</label>
              <textarea
                class="form-control mt-1"
                id="message"
                name="message"
                placeholder="Message"
                rows="8"></textarea>
            </div>
            <div class="row">
              <div class="col text-end mt-2">
                <button type="submit" class="btn btn-success btn-lg px-3">
                  Let’s Talk
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
